import React, { useEffect, useState, useRef } from 'react';
import { Card, Row, Col, Typography, List, Tag, Modal, Button, Spin, Progress, Steps, Form, Input, Select, message } from 'antd';
import { 
  ArrowDownOutlined, 
  ArrowUpOutlined,
  PlayCircleOutlined,
  HistoryOutlined,
  LoadingOutlined,
  RocketOutlined,
  LineChartOutlined,
  DollarOutlined,
  TrophyOutlined,
  BarChartOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import TradingChart from './TradingChart';
import SpiderRadarChart from './SpiderRadarChart';
import MQTTService from '../../services/mqtt.service';
import { useDispatch } from 'react-redux';
import { setBacktestProgress, updateBacktestResult } from '../../store/slices/strategySlice';
import { TRADING_CONFIG } from '../../config/trading.config';
import TradingService from '../../services/trading.service';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useParams, useNavigate } from 'react-router-dom';

const { Text, Title } = Typography;
const { Step } = Steps;

interface Trade {
  date: string;
  type: 'buy' | 'sell';
  profit: number;
}

interface BacktestResultsProps {
  jobId?: number;
  isLoading: boolean;
  progress?: number;
  onBacktest: () => void;
  onDeploy?: () => void;
  results?: {
    profitLoss?: number;
    winRate?: number;
    totalTrades?: number;
    averageProfit?: number;
    averageLoss?: number;
    maxDrawdown?: number;
    duration?: string;
    trades?: Trade[];
    chartData?: any[];
  };
}

const BacktestResults: React.FC<BacktestResultsProps> = ({ 
  jobId,
  isLoading,
  progress = 0,
  onBacktest,
  onDeploy,
  results = {} 
}) => {
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [isDeployModalVisible, setIsDeployModalVisible] = useState(false);
  const [isBotCreated, setIsBotCreated] = useState(false);
  const [isChartModalVisible, setIsChartModalVisible] = useState(false);
  const [backtestProgress, setBacktestProgressState] = useState(progress);
  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  
  const { id: strategyId } = useParams<{ id: string }>();
  const strategyName = useAppSelector(state => state.strategy.strategyName);
  
  const mqttService = useRef(MQTTService.getInstance());

  useEffect(() => {
    if (jobId && isLoading) {
      const currentMqttService = mqttService.current;
      const handleBacktestUpdate = (data: any) => {
        if (data.progress !== undefined) {
          setBacktestProgressState(data.progress);
          dispatch(setBacktestProgress(data.progress));
        }
        if (data.status === 'success' && data.result) {
          dispatch(updateBacktestResult(data.result));
        }

        const statusSteps = ['startup', 'dataload', 'analyze', 'convert', 'backtest', 'success'];
        const stepIndex = statusSteps.indexOf(data.status);
        if (stepIndex !== -1) {
          setCurrentStep(stepIndex);
        }
      };

      currentMqttService.subscribeToBacktest(jobId, handleBacktestUpdate);

      return () => {
        currentMqttService.unsubscribeFromBacktest(jobId, handleBacktestUpdate);
      };
    }
  }, [jobId, isLoading, dispatch]);

  useEffect(() => {
    setBacktestProgressState(progress);
  }, [progress]);

  const handleCreateBot = async (values: any) => {
    try {
      const response = await TradingService.createBot(values);
      if (response.success) {
        message.success('Tạo bot thành công.');
        setIsBotCreated(true);
        form.resetFields();
      } else {
        message.error(response.error || 'Có lỗi xảy ra');
      }
    } catch (error) {
      message.error('Lỗi khi tạo bot');
    }
  };

  return (
    <Card
      className="strategy-card"
      title={
        <div style={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Kiểm thử chiến lược
          </Title>
          <button
            type="button"
            onClick={onBacktest}
            disabled={isLoading}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: '8px',
              padding: '4px 15px',
              borderRadius: '6px',
              border: 'none',
              background: '#1677ff',
              color: 'white',
              cursor: isLoading ? 'not-allowed' : 'pointer',
              fontSize: '14px',
              transition: 'all 0.3s',
              opacity: isLoading ? 0.7 : 1,
            }}
          >
            {isLoading ? <LoadingOutlined spin /> : <PlayCircleOutlined />}
            {isLoading ? 'Đang xử lý...' : 'Backtest'}
          </button>
        </div>
      }
      style={{ borderRadius: '16px' }}
    >
      {isLoading ? (
        <div style={{ 
          padding: '40px 0', 
          textAlign: 'center',
          background: 'rgba(0,0,0,0.02)',
          borderRadius: '12px',
          margin: '8px 0'
        }}>
          <Spin 
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} 
            tip="Đang phân tích chiến lược..."
          />
          {currentStep === 4 && (
            <div style={{ width: '80%', margin: '20px auto 0' }}>
              <Progress 
                percent={backtestProgress} 
                status="active"
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
              />
              <Text type="secondary" style={{ fontSize: '13px' }}>
                Đã hoàn thành {backtestProgress}% quá trình phân tích
              </Text>
            </div>
          )}
          <Steps current={currentStep} size="small" style={{ marginTop: '20px', fontSize: '12px', padding: '0 10px' }}>
            <Step title="Khởi động" />
            <Step title="Tải Data" />
            <Step title="Phân tích" />
            <Step title="Convert" />
            <Step title="Backtest" />
            <Step title="Hoàn thành" />
          </Steps>
        </div>
      ) : (
        <>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={16}>
              <Card size="small" style={{ borderRadius: '12px', border: 'none' }}>
                <SpiderRadarChart />
              </Card>
            </Col>
            <Col xs={24} sm={8}>
            <div style={{ 
            marginTop: '16px',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '8px'
          }}>
            <Button 
              type="default"
              icon={<HistoryOutlined />}
              onClick={() => setIsHistoryVisible(true)}
              style={{ borderRadius: '6px' }}
            >
              Xem lịch sử giao dịch
            </Button>
            <Button 
              type="default"
              icon={<LineChartOutlined />}
              onClick={() => setIsChartModalVisible(true)}
              style={{ borderRadius: '6px' }}
            >
              Xem chart
            </Button>
          </div>

              <div style={{ fontFamily: 'Courier New, monospace', fontSize: '12px', padding: '10px' }}>
                <List
                  itemLayout="horizontal"
                  dataSource={[
                    { label: 'Lợi nhuận/Lỗ', value: results.profitLoss !== undefined ? results.profitLoss : 0, icon: <DollarOutlined /> },
                    { label: 'Tỷ lệ thắng', value: `${results.winRate !== undefined ? results.winRate : 0}%`, icon: <TrophyOutlined /> },
                    { label: 'Tổng số giao dịch', value: results.totalTrades !== undefined ? results.totalTrades : 0, icon: <BarChartOutlined /> },
                    { label: 'Lợi nhuận trung bình', value: results.averageProfit !== undefined ? results.averageProfit : 0, icon: <ArrowUpOutlined /> },
                    { label: 'Lỗ trung bình', value: results.averageLoss !== undefined ? results.averageLoss : 0, icon: <ArrowDownOutlined /> },
                    { label: 'Giảm tối đa', value: results.maxDrawdown !== undefined ? results.maxDrawdown : 0, icon: <LineChartOutlined /> },
                    { label: 'Thời gian', value: results.duration !== undefined ? results.duration : 'N/A', icon: <ClockCircleOutlined /> },
                  ]}
                  renderItem={item => (
                    <List.Item style={{ border: 'none', display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {item.icon}
                        <Text strong style={{ fontSize: '14px' }}>{item.label}:</Text>
                      </div>
                      <Text style={{ fontSize: '12px' }}>{item.value}</Text>
                    </List.Item>
                  )}
                />
              </div>
            </Col>
          </Row>
          <div style={{
            marginTop: '24px',
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Button
              type="primary"
              size="large"
              icon={<RocketOutlined />}
              onClick={() => setIsDeployModalVisible(true)}
              style={{ 
                borderRadius: '8px',
                height: '48px',
                fontSize: '16px',
                padding: '0 32px',
                background: '#52c41a',
                borderColor: '#52c41a'
              }}
            >
              Triển khai chiến lược
            </Button>
          </div>
          <Modal
            title="Lịch sử giao dịch"
            open={isHistoryVisible}
            onCancel={() => setIsHistoryVisible(false)}
            footer={null}
            width={600}
          >
            <List
              size="small"
              dataSource={results.trades || []}
              renderItem={trade => (
                <List.Item style={{ padding: '8px 0' }}>
                  <div style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '12px',
                    color: trade.profit >= 0 ? '#52c41a' : '#ff4d4f',
                    fontSize: '14px'
                  }}>
                    {trade.profit >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                    <Text style={{ fontSize: '14px' }}>{trade.date}</Text>
                    <Tag color={trade.type === 'buy' ? 'blue' : 'orange'} style={{ fontSize: '13px', margin: 0 }}>
                      {trade.type === 'buy' ? 'MUA' : 'BÁN'}
                    </Tag>
                    <Text strong style={{ fontSize: '14px' }}>${trade.profit.toFixed(2)}</Text>
                  </div>
                </List.Item>
              )}
            />
          </Modal>
          <Modal
            title="Triển khai chiến lược"
            open={isDeployModalVisible}
            onCancel={() => setIsDeployModalVisible(false)}
            footer={isBotCreated ? null : [
              <Button key="create" type="primary" onClick={form.submit}>
                Tạo Bot
              </Button>,
              <Button key="cancel" onClick={() => setIsDeployModalVisible(false)}>
                Đóng
              </Button>,
            ]}
            width={520}
          >
            {isBotCreated ? (
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <Text strong>Bot đã được tạo thành công!</Text>
                <div style={{ marginTop: '20px' }}>
                  <Button type="primary" onClick={() => navigate('/bot')}>
                    Đi đến danh sách bot
                  </Button>
                </div>
              </div>
            ) : (
              <Form
                form={form}
                layout="vertical"
                onFinish={handleCreateBot}
                initialValues={{
                  strategy: strategyId,
                }}
              >
                <Form.Item
                  name="name"
                  label="Tên Bot"
                  rules={[{ required: true, message: 'Vui lòng nhập tên bot' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="strategy"
                  label="Chiến lược"
                  rules={[{ required: true, message: 'Vui lòng chọn chiến lược' }]}
                >
                  <Select>
                    <Select.Option value={strategyId}>
                      {strategyName}
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="symbol"
                  label="Cặp tiền"
                  rules={[{ required: true, message: 'Vui lòng chọn cặp tiền' }]}
                >
                  <Select>
                    {TRADING_CONFIG.SYMBOLS.map(symbol => (
                      <Select.Option key={symbol.value} value={symbol.value}>
                        {symbol.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            )}
          </Modal>
          <Modal
            title="Chart Backtest"
            open={isChartModalVisible}
            onCancel={() => setIsChartModalVisible(false)}
            footer={null}
            width="100vw"
            style={{ top: 0, left: 0, padding: 0, height: '100vh', overflow: 'hidden', borderRadius: 0 }}
            bodyStyle={{ height: '100vh', padding: 0, borderRadius: 0 }}
            mask={false}
            modalRender={modal => <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, borderRadius: 0 }}>{modal}</div>}
            className="no-border-radius-modal"
          >
            {results.chartData ? (
              <div style={{ height: '70%' }}>
                <TradingChart data={results.chartData} />
              </div>
            ) : (
              <Text type="secondary" style={{ textAlign: 'center', marginTop: '20px' }}>
                Không có dữ liệu để hiển thị biểu đồ.
              </Text>
            )}
          </Modal>
        </>
      )}
    </Card>
  );
};

export default BacktestResults;
