import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { StrategyState, BacktestRequestPayload } from '../types/strategy.types';
import TradingService from '../../services/trading.service';
import { mapAPIToUIStrategy } from '../../components/strategy/types';
import { chartData } from '../../data/chartData';

interface BacktestTrade {
  date: string;
  type: 'buy' | 'sell';
  profit: number;
}

interface BacktestResult {
  total_profit: number;
  win_rate: number;
  total_trades: number;
  avg_profit: number;
  avg_loss: number;
  max_drawdown: number;
  duration: string;
  trades: BacktestTrade[];
  chart_data?: any[];
}

interface BacktestJob {
  id: number;
  status: 'pending' | 'running' | 'completed' | 'error';
  error_message?: string;
  result?: BacktestResult;
}

interface StrategyResponse {
  id: string;
  name: string;
  parameters: any;
}

const initialState: StrategyState = {
  strategy: {
    entryConditions: {
      buy: [],
      sell: [],
    },
    exitConditions: {
      takeProfit: '',
      stopLoss: '',
    },
    indicators: [],
  },
  strategyName: 'Chiến lược giao dịch mới',
  isEditingName: false,
  selectedSymbol: 'BTCUSDT',
  selectedTimeframe: '1m',
  isBacktesting: false,
  backtestProgress: 0,
  backtestResult: null,
  currentBacktestJobId: null,
  error: null,
};

export const fetchStrategy = createAsyncThunk<StrategyResponse, string>(
  'strategy/fetchStrategy',
  async (id: string) => {
    const response = await TradingService.getStrategyById(id);
    if (!response.data) {
      throw new Error('Không tìm thấy chiến lược');
    }
    return response.data;
  }
);

export const startBacktest = createAsyncThunk<
  BacktestJob,
  BacktestRequestPayload,
  {
    rejectValue: string;
  }
>(
  'strategy/startBacktest',
  async (payload: BacktestRequestPayload, { rejectWithValue }) => {
    try {
      const backtestRequest = {
        strategy: parseInt(payload.id),
        symbol: payload.symbol,
        start_date: "2024-01-01T00:00:00Z",
        end_date: "2024-01-31T23:59:59Z",
        parameters: {
          timeframe: payload.timeframe,
          take_profit: parseFloat(payload.strategy.exitConditions.takeProfit) || 2.0,
          stop_loss: parseFloat(payload.strategy.exitConditions.stopLoss) || 1.0,
          leverage: 10
        }
      };

      const response = await TradingService.createBacktest(backtestRequest);
      if (!response.data?.id) {
        return rejectWithValue('Invalid backtest response');
      }
      
      const statusResponse = await TradingService.getBacktestStatus(response.data.id);
      if (!statusResponse.data) {
        return rejectWithValue('Invalid backtest status response');
      }

      const job = statusResponse.data as BacktestJob;
      if (job.status === 'error') {
        return rejectWithValue(job.error_message || 'Backtest failed');
      }
      
      return job;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Unknown error occurred');
    }
  }
);

const strategySlice = createSlice({
  name: 'strategy',
  initialState,
  reducers: {
    setStrategy: (state, action: PayloadAction<typeof initialState.strategy>) => {
      state.strategy = action.payload;
    },
    setStrategyName: (state, action: PayloadAction<string>) => {
      state.strategyName = action.payload;
    },
    setIsEditingName: (state, action: PayloadAction<boolean>) => {
      state.isEditingName = action.payload;
    },
    setSelectedSymbol: (state, action: PayloadAction<string>) => {
      state.selectedSymbol = action.payload;
    },
    setSelectedTimeframe: (state, action: PayloadAction<string>) => {
      state.selectedTimeframe = action.payload;
    },
    setBacktestProgress: (state, action: PayloadAction<number>) => {
      state.backtestProgress = action.payload;
    },
    resetBacktest: (state) => {
      state.isBacktesting = false;
      state.backtestProgress = 0;
      state.backtestResult = null;
      state.currentBacktestJobId = null;
    },
    updateStrategyFromResponse: (state, action: PayloadAction<{
      entryConditions?: {
        buy: string[];
        sell: string[];
      };
      exitConditions?: {
        takeProfit: string;
        stopLoss: string;
      };
      indicators?: string[];
    }>) => {
      const { entryConditions, exitConditions, indicators } = action.payload;
      if (entryConditions) {
        state.strategy.entryConditions = entryConditions;
      }
      if (exitConditions) {
        state.strategy.exitConditions = exitConditions;
      }
      if (indicators) {
        state.strategy.indicators = indicators;
      }
    },
    updateBacktestResult: (state, action: PayloadAction<string>) => {
      try {
        const parsedResult = JSON.parse(action.payload);
        const backtestResults = parsedResult.backtest_results;
        state.backtestResult = {
          profitLoss: parseFloat(backtestResults.statistics.total_profit),
          winRate: parseFloat(backtestResults.statistics.win_rate),
          totalTrades: backtestResults.statistics.total_trades,
          averageProfit: parseFloat(backtestResults.statistics.avg_profit),
          averageLoss: 0, // Assuming avg_loss is not provided
          maxDrawdown: parseFloat(backtestResults.statistics.max_drawdown),
          duration: '', // Assuming duration is not provided
          trades: Array.isArray(backtestResults.trades) ? backtestResults.trades.map((t: BacktestTrade) => ({
            date: t.date,
            type: t.type,
            profit: t.profit
          })) : [],
          chartData: chartData // Assuming chartData is not provided
        };
        state.isBacktesting = false;
        state.backtestProgress = 100;
      } catch (error) {
        console.error('Error parsing backtest result:', error);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Strategy
      .addCase(fetchStrategy.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchStrategy.fulfilled, (state, action) => {
        if (action.payload) {
          state.strategyName = action.payload.name;
          state.strategy = mapAPIToUIStrategy(action.payload);
        }
      })
      .addCase(fetchStrategy.rejected, (state, action) => {
        state.error = action.error.message || 'Không thể tải chiến lược';
        message.error('Chiến lược không tồn tại hoặc đã bị xóa');
      })
      // Start Backtest
      .addCase(startBacktest.pending, (state) => {
        state.isBacktesting = true;
        state.backtestProgress = 0;
        state.backtestResult = null;
        state.error = null;
      })
      .addCase(startBacktest.fulfilled, (state, action) => {
        const job = action.payload;
        state.currentBacktestJobId = job.id;

        if (job.status === 'completed' && job.result) {
          state.backtestProgress = 100;
          state.isBacktesting = false;
          
          state.backtestResult = {
            profitLoss: job.result.total_profit,
            winRate: job.result.win_rate,
            totalTrades: job.result.total_trades,
            averageProfit: job.result.avg_profit,
            averageLoss: job.result.avg_loss,
            maxDrawdown: job.result.max_drawdown,
            duration: job.result.duration,
            trades: job.result.trades.map(t => ({
              date: t.date,
              type: t.type,
              profit: t.profit
            })),
            chartData: job.result.chart_data ?? chartData
          };
        }
      })
      .addCase(startBacktest.rejected, (state, action) => {
        state.isBacktesting = false;
        state.backtestProgress = 0;
        state.error = action.payload || 'Không thể bắt đầu backtest';
        message.error(state.error);
      });
  },
});

export const {
  setStrategy,
  setStrategyName,
  setIsEditingName,
  setSelectedSymbol,
  setSelectedTimeframe,
  setBacktestProgress,
  resetBacktest,
  updateStrategyFromResponse,
  updateBacktestResult,
} = strategySlice.actions;

export default strategySlice.reducer;
