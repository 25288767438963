import React from 'react';
import { Card } from 'antd';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import annotationsModule from 'highcharts/modules/annotations';

// Initialize Highcharts modules
annotationsModule(Highcharts);

interface TradingChartProps {
  data: any[];
}

const TradingChart: React.FC<TradingChartProps> = ({ data }) => {
  const chartOptions = {
    chart: {
      height: '550px' // Đặt chiều cao của highcharts-background là 70% của modal
    },
    yAxis: {
      plotBands: [{
        color: 'rgba(169, 255, 101, 0.4)',
        from: 182.94,
        to: 177.57,
        zIndex: 3,
        label: {
          text: 'Resistance Zone'
        }
      }]
    },
    annotations: [{
      type: 'crookedLine',
      langKey: 'Trendline',
      typeOptions: {
        points: [{
          x: 1636727400000,
          y: 147.48
        }, {
          x: 1642516200000,
          y: 182.5
        }]
      },
      shapeOptions: {
        stroke: 'orange',
        strokeWidth: 2
      }
    }],
    series: [{
      id: 'main',
      type: 'candlestick',
      color: '#FF6F6F',
      upColor: '#6FB76F',
      data: data,
      dataGrouping: {
        enabled: false
      }
    }]
  };

  return (
    <Card style={{ borderRadius: '12px', height: '100%' }}>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={chartOptions}
        containerProps={{ style: { height: '100%' } }} // Đặt chiều cao của HighchartsReact là 100% của Card
      />
    </Card>
  );
};

export default TradingChart;
