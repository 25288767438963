import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Statistic, Row, Col, Modal, Form, Input, Select, Space, Grid, message, Switch } from 'antd';
import { 
  PlusOutlined, 
  RobotOutlined, 
  DollarOutlined, 
  BarChartOutlined, 
  RocketOutlined, 
  ExclamationCircleOutlined, 
  SettingOutlined,
  WarningOutlined
} from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import TradingService from '../services/trading.service';
import { Bot, BotStatistics, CreateBotRequest, Strategy } from '../types/api.types';
import { TRADING_CONFIG } from '../config/trading.config';
import { useNavigate } from 'react-router-dom';

const { useBreakpoint } = Grid;
const { confirm } = Modal;

interface BotDashboardProps {
  onCreateStrategy: () => void;
  onBotSetup: (bot: Bot) => void;
}

const initialStatistics: BotStatistics = {
  total_profit: "0",
  active_bots: 0,
  total_trades: 0,
  average_win_rate: 0
};

const BotDashboard: React.FC<BotDashboardProps> = ({ onCreateStrategy, onBotSetup }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [bots, setBots] = useState<Bot[]>([]);
  const [strategies, setStrategies] = useState<Strategy[]>([]);
  const [statistics, setStatistics] = useState<BotStatistics>(initialStatistics);
  const navigate = useNavigate();

  const fetchBots = async () => {
    try {
      const response = await TradingService.getBots();
      if (response.success && response.data) {
        setBots(response.data);
      } else {
        message.error('Không thể tải danh sách bot');
      }
    } catch (error) {
      message.error('Lỗi khi tải danh sách bot');
    }
  };

  const fetchStrategies = async () => {
    try {
      const response = await TradingService.getStrategies();
      if (response.success && response.data) {
        setStrategies(response.data);
      } else {
        message.error('Không thể tải danh sách chiến lược');
      }
    } catch (error) {
      message.error('Lỗi khi tải danh sách chiến lược');
    }
  };

  const fetchStatistics = async () => {
    try {
      const response = await TradingService.getBotStatistics();
      if (response.success && response.data) {
        setStatistics(prevState => ({
          ...prevState,
          ...response.data
        }));
      } else {
        message.error('Không thể tải thống kê');
      }
    } catch (error) {
      message.error('Lỗi khi tải thống kê');
    }
  };

  useEffect(() => {
    fetchBots();
    fetchStatistics();
  }, []);

  useEffect(() => {
    if (isModalVisible) {
      fetchStrategies();
    }
  }, [isModalVisible]);

  const showDeleteConfirm = (botId: string, botName: string) => {
    confirm({
      title: 'Xác nhận xóa bot',
      icon: <ExclamationCircleOutlined />,
      content: `Bạn có chắc chắn muốn xóa bot "${botName}"? Hành động này không thể hoàn tác.`,
      okText: 'Xóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk: async () => {
        try {
          setLoading(true);
          const response = await TradingService.deleteBot(botId);
          if (response.success) {
            message.success('Xóa bot thành công');
            fetchBots();
            fetchStatistics();
          } else {
            message.error(response.error || 'Có lỗi xảy ra khi xóa bot');
          }
        } catch (error) {
          message.error('Có lỗi xảy ra khi xóa bot');
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const handleBotAction = async (botId: string, action: 'start' | 'stop' | 'delete', botName?: string) => {
    if (action === 'delete') {
      showDeleteConfirm(botId, botName || 'Bot');
      return;
    }

    try {
      setLoading(true);
      const response = await TradingService.updateBotStatus(botId, action === 'start' ? 'running' : 'stopped');
      if (response.success) {
        message.success(`${action === 'start' ? 'Khởi động' : 'Dừng'} bot thành công`);
        fetchBots();
        fetchStatistics();
      } else {
        message.error(response.error || 'Có lỗi xảy ra');
      }
    } catch (error) {
      message.error('Có lỗi xảy ra');
    } finally {
      setLoading(false);
    }
  };

  const showLiveModeConfirm = (botId: string) => {
    confirm({
      title: 'Xác nhận chuyển sang chế độ Live',
      icon: <WarningOutlined style={{ color: '#ff4d4f' }} />,
      content: (
        <div>
          <p style={{ color: '#ff4d4f', fontWeight: 'bold' }}>
            CẢNH BÁO: Bot sẽ giao dịch bằng tiền thật trong tài khoản của bạn!
          </p>
          <p>
            Hãy đảm bảo bạn đã:
          </p>
          <ul>
            <li>Kiểm tra kỹ chiến lược giao dịch</li>
            <li>Thiết lập đúng các thông số quản lý rủi ro</li>
            <li>Hiểu rõ rủi ro khi giao dịch bằng tiền thật</li>
          </ul>
        </div>
      ),
      okText: 'Xác nhận',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk: async () => {
        try {
          setLoading(true);
          const response = await TradingService.updateBotMode(botId, 'live');
          if (response.success) {
            message.success('Chuyển sang chế độ Live thành công');
            fetchBots();
          } else {
            message.error(response.error || 'Có lỗi xảy ra');
          }
        } catch (error) {
          message.error('Có lỗi xảy ra');
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const handleModeChange = async (botId: string, checked: boolean) => {
    if (checked) {
      showLiveModeConfirm(botId);
    } else {
      try {
        setLoading(true);
        const response = await TradingService.updateBotMode(botId, 'dry');
        if (response.success) {
          message.success('Chuyển sang chế độ Dry thành công');
          fetchBots();
        } else {
          message.error(response.error || 'Có lỗi xảy ra');
        }
      } catch (error) {
        message.error('Có lỗi xảy ra');
      } finally {
        setLoading(false);
      }
    }
  };

  const getColumns = (): ColumnsType<Bot> => {
    if (screens.xs) {
      return [
        {
          title: 'Live/Dry',
          key: 'mode',
          width: 90,
          render: (_, record: Bot) => (
            <Switch
              checked={record.mode === 'live'}
              onChange={(checked) => handleModeChange(record.id, checked)}
              disabled={record.status === 'running'}
              checkedChildren="Live"
              unCheckedChildren="Dry"
              size="small"
            />
          ),
        },
        {
          title: 'Tên Bot',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Trạng thái',
          dataIndex: 'status',
          key: 'status',
          render: (status: string) => (
            <span style={{ 
              color: status === 'running' ? 'green' : 'gray',
              fontWeight: 500
            }}>
              {status === 'running' ? 'Đang chạy' : 'Đã dừng'}
            </span>
          ),
        },
        {
          title: 'Hành động',
          key: 'action',
          render: (_, record: Bot) => (
            <Space size="small" wrap>
              <Button
                type={record.status === 'running' ? 'default' : 'primary'}
                size="small"
                onClick={() => handleBotAction(record.id, record.status === 'running' ? 'stop' : 'start')}
                loading={loading}
              >
                {record.status === 'running' ? 'Dừng' : 'Chạy'}
              </Button>
              <Button
                type="default"
                size="small"
                icon={<SettingOutlined />}
                onClick={() => navigate(`/bot/${record.id}`)}
              >
                Chi tiết
              </Button>
              <Button
                danger
                size="small"
                onClick={() => handleBotAction(record.id, 'delete', record.name)}
                loading={loading}
              >
                Xóa
              </Button>
            </Space>
          ),
        },
      ];
    }

    return [
      {
        title: 'Live/Dry',
        key: 'mode',
        fixed: 'left' as const,
        width: 90,
        render: (_, record: Bot) => (
          <Switch
            checked={record.mode === 'live'}
            onChange={(checked) => handleModeChange(record.id, checked)}
            disabled={record.status === 'running'}
            checkedChildren="Live"
            unCheckedChildren="Dry"
          />
        ),
      },
      {
        title: 'Tên Bot',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left' as const,
      },
      {
        title: 'Chiến lược',
        dataIndex: 'strategy',
        key: 'strategy',
      },
      {
        title: 'Cặp tiền',
        dataIndex: 'symbol',
        key: 'symbol',
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
        render: (status: string) => (
          <span style={{ 
            color: status === 'running' ? 'green' : 'gray',
            fontWeight: 500
          }}>
            {status === 'running' ? 'Đang chạy' : 'Đã dừng'}
          </span>
        ),
      },
      {
        title: 'Hành động',
        key: 'action',
        fixed: 'right' as const,
        render: (_, record: Bot) => (
          <Space size="small" wrap>
            <Button
              type={record.status === 'running' ? 'default' : 'primary'}
              size="middle"
              onClick={() => handleBotAction(record.id, record.status === 'running' ? 'stop' : 'start')}
              loading={loading}
            >
              {record.status === 'running' ? 'Dừng' : 'Chạy'}
            </Button>
            <Button
              type="default"
              size="middle"
              icon={<SettingOutlined />}
              onClick={() => navigate(`/bot/${record.id}`)}
            >
              Chi tiết
            </Button>
            <Button
              danger
              size="middle"
              onClick={() => handleBotAction(record.id, 'delete', record.name)}
              loading={loading}
            >
              Xóa
            </Button>
          </Space>
        ),
      },
    ];
  };

  const handleCreateBot = async (values: CreateBotRequest) => {
    try {
      setLoading(true);
      const response = await TradingService.createBot(values);
      if (response.success) {
        message.success('Tạo bot thành công');
        setIsModalVisible(false);
        form.resetFields();
        fetchBots();
        fetchStatistics();
      } else {
        message.error(response.error || 'Có lỗi xảy ra');
      }
    } catch (error) {
      message.error('Lỗi khi tạo bot');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} lg={6}>
          <Card bodyStyle={{ padding: screens.xs ? 12 : 24 }}>
            <Statistic
              title="Tổng lợi nhuận"
              value={parseFloat(statistics.total_profit)}
              precision={8}
              prefix={<DollarOutlined />}
              valueStyle={{
                color: parseFloat(statistics.total_profit) >= 0 ? '#3f8600' : '#cf1322',
                fontSize: screens.xs ? '16px' : '24px'
              }}
              suffix="$"
            />
          </Card>
        </Col>
        <Col xs={12} sm={12} lg={6}>
          <Card bodyStyle={{ padding: screens.xs ? 12 : 24 }}>
            <Statistic
              title="Bot đang chạy"
              value={statistics.active_bots}
              prefix={<RobotOutlined />}
              valueStyle={{
                fontSize: screens.xs ? '16px' : '24px'
              }}
            />
          </Card>
        </Col>
        <Col xs={12} sm={12} lg={6}>
          <Card bodyStyle={{ padding: screens.xs ? 12 : 24 }}>
            <Statistic
              title="Tổng giao dịch"
              value={statistics.total_trades}
              prefix={<BarChartOutlined />}
              valueStyle={{
                fontSize: screens.xs ? '16px' : '24px'
              }}
            />
          </Card>
        </Col>
        <Col xs={12} sm={12} lg={6}>
          <Card bodyStyle={{ padding: screens.xs ? 12 : 24 }}>
            <Statistic
              title="Tỷ lệ thắng TB"
              value={statistics.average_win_rate}
              precision={1}
              suffix="%"
              valueStyle={{
                fontSize: screens.xs ? '16px' : '24px'
              }}
            />
          </Card>
        </Col>
      </Row>

      <div style={{ margin: '16px 0', display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
        <Button
          type="primary"
          icon={<RocketOutlined />}
          onClick={onCreateStrategy}
          size={screens.xs ? 'middle' : 'large'}
        >
          Tạo chiến lược
        </Button>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsModalVisible(true)}
          size={screens.xs ? 'middle' : 'large'}
        >
          Tạo Bot mới
        </Button>
      </div>

      <Card
        title="Danh sách Bot"
        bodyStyle={{ padding: screens.xs ? 8 : 24 }}
      >
        <Table
          columns={getColumns()}
          dataSource={bots}
          scroll={{ x: true }}
          size={screens.xs ? 'small' : 'middle'}
          pagination={{
            size: screens.xs ? 'small' : 'default',
            pageSize: screens.xs ? 5 : 10,
          }}
          loading={loading}
        />
      </Card>

      <Modal
        title="Tạo Bot mới"
        open={isModalVisible}
        onOk={form.submit}
        onCancel={() => setIsModalVisible(false)}
        width={screens.xs ? '95%' : 520}
        confirmLoading={loading}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleCreateBot}
        >
          <Form.Item
            name="name"
            label="Tên Bot"
            rules={[{ required: true, message: 'Vui lòng nhập tên bot' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="strategy"
            label="Chiến lược"
            rules={[{ required: true, message: 'Vui lòng chọn chiến lược' }]}
          >
            <Select loading={loading}>
              {strategies.map(strategy => (
                <Select.Option key={strategy.id} value={strategy.id}>
                  {strategy.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="symbol"
            label="Cặp tiền"
            rules={[{ required: true, message: 'Vui lòng chọn cặp tiền' }]}
          >
            <Select>
              {TRADING_CONFIG.SYMBOLS.map(symbol => (
                <Select.Option key={symbol.value} value={symbol.value}>
                  {symbol.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default BotDashboard;
