import React, { createContext, useContext, useState, useEffect } from 'react';
import AuthService from '../services/auth.service';
import { message } from 'antd';

interface AuthContextType {
  isAuthenticated: boolean;
  username: string | null;
  loading: boolean;
  login: (username: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [username, setUsername] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const isAuth = AuthService.isAuthenticated();
        setIsAuthenticated(isAuth);
        if (isAuth) {
          const storedUsername = AuthService.getUsername();
          setUsername(storedUsername);
        }
      } finally {
        setLoading(false);
      }
    };
    checkAuth();
  }, []);

  const login = async (username: string, password: string) => {
    try {
      const response = await AuthService.login({ username, password });
      if (response.success) {
        setIsAuthenticated(true);
        setUsername(username);
        message.success('Đăng nhập thành công');
        return true;
      } else {
        message.error(response.error || 'Đăng nhập thất bại');
        return false;
      }
    } catch (error) {
      message.error('Có lỗi xảy ra khi đăng nhập');
      return false;
    }
  };

  const logout = async () => {
    try {
      const response = await AuthService.logout();
      if (response.success) {
        setIsAuthenticated(false);
        setUsername(null);
        message.success('Đăng xuất thành công');
      } else {
        message.error(response.error || 'Đăng xuất thất bại');
      }
    } catch (error) {
      message.error('Có lỗi xảy ra khi đăng xuất');
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, username, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
