import React, { useEffect } from 'react';
import { Layout, Row, Col } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { useStrategyChat } from '../hooks/useStrategyChat';
import StrategyChat from './strategy/StrategyChat';
import StrategyHeader from './strategy/StrategyHeader';
import StrategyConditions from './strategy/StrategyConditions';
import BacktestResults from './strategy/BacktestResults';
import { RootState } from '../store';
import {
  fetchStrategy,
  startBacktest,
  setStrategyName,
  setIsEditingName,
  setSelectedSymbol,
  setSelectedTimeframe,
} from '../store/slices/strategySlice';

const { Content } = Layout;

const SYMBOLS = ['BTCUSDT', 'ETHUSDT', 'BNBUSDT', 'ADAUSDT', 'DOGEUSDT'];
const TIMEFRAMES = ['1m', '3m', '5m', '15m', '30m', '1h', '2h', '4h', '6h', '8h', '12h', '1d', '3d', '1w', '1M'];

const StrategyBuilder: React.FC = () => {
  const { id } = useParams<{id: string}>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const {
    strategy,
    strategyName,
    isEditingName,
    selectedSymbol,
    selectedTimeframe,
    isBacktesting,
    backtestProgress,
    backtestResult,
    currentBacktestJobId,
  } = useAppSelector((state: RootState) => state.strategy);

  const {
    messages,
    inputMessage,
    isTyping,
    isThinking,
    isOnline,
    showScrollButton,
    functionCall,
    thinkingMessage,
    setInputMessage,
    setShowScrollButton,
    handleSendMessage,
    handleResetChat,
  } = useStrategyChat(parseInt(id || '0'));

  useEffect(() => {
    if (!id) {
      navigate('/strategies');
      return;
    }

    dispatch(fetchStrategy(id));
  }, [id, dispatch, navigate]);

  const handleBacktest = () => {
    if (!id) return;

    dispatch(startBacktest({
      id,
      symbol: selectedSymbol,
      timeframe: selectedTimeframe,
      strategy,
    }));
  };

  return (
    <Layout style={{ 
      height: '100vh',
      background: '#f5f5f5',
      overflow: 'hidden',
    }}>
      <Content style={{ height: '100%', padding: '16px' }}>
        <Row gutter={[16, 16]} style={{ height: '100%', margin: 0 }}>
          {/* Left Column - Chat Interface */}
          <Col xs={24} lg={10} style={{ height: '100%' }}>
            <StrategyChat
              messages={messages}
              isTyping={isTyping}
              isThinking={isThinking}
              isOnline={isOnline}
              showScrollButton={showScrollButton}
              inputMessage={inputMessage}
              functionCall={functionCall}
              thinkingMessage={thinkingMessage}
              onInputChange={setInputMessage}
              onSendMessage={handleSendMessage}
              onScrollToBottom={() => setShowScrollButton(false)}
              onReset={handleResetChat}
            />
          </Col>

          {/* Right Column - Strategy Summary */}
          <Col xs={24} lg={14} style={{ height: '100%', overflowY: 'auto' }}>
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <StrategyHeader
                  strategyName={strategyName}
                  isEditingName={isEditingName}
                  selectedSymbol={selectedSymbol}
                  selectedTimeframe={selectedTimeframe}
                  symbols={SYMBOLS}
                  timeframes={TIMEFRAMES}
                  onStrategyNameChange={(name) => dispatch(setStrategyName(name))}
                  onSymbolChange={(symbol) => dispatch(setSelectedSymbol(symbol))}
                  onTimeframeChange={(timeframe) => dispatch(setSelectedTimeframe(timeframe))}
                  onEditNameStart={() => dispatch(setIsEditingName(true))}
                  onEditNameEnd={() => dispatch(setIsEditingName(false))}
                />
              </Col>

              <Col xs={24}>
                <StrategyConditions strategy={strategy} />
              </Col>

              <Col xs={24}>
                <BacktestResults 
                  jobId={currentBacktestJobId || undefined}
                  isLoading={isBacktesting}
                  progress={backtestProgress}
                  onBacktest={handleBacktest}
                  results={backtestResult || undefined}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default StrategyBuilder;
