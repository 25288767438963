import React from 'react';
import { Row, Col, Card, Statistic, Typography, Table, Tag, Progress, Tooltip, Alert, Divider } from 'antd';
import type { ColumnType } from 'antd/es/table';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import {
  RiseOutlined,
  InfoCircleOutlined,
  RobotOutlined,
  CheckCircleOutlined,
  BankOutlined,
  StockOutlined,
  GlobalOutlined
} from '@ant-design/icons';

// Initialize Highcharts modules
HighchartsMore(Highcharts);

// Disable Highcharts credits globally
Highcharts.setOptions({
  credits: {
    enabled: false
  }
});

const { Title, Text } = Typography;

// Define interfaces for data types
interface MarketDataType {
  name: string;
  VN_Index: number;
  VN30: number;
  Volume: number;
  RSI: number;
  MA20: number;
  MA50: number;
  BB_Upper: number;
  BB_Lower: number;
  MACD: number;
  Signal: number;
}

interface SectorAnalysisType {
  key: string;
  sector: string;
  marketCap: number;
  performance: number;
  pe: number;
  outlook: string;
  topPicks: string[];
  momentum: number;
}

interface SectorAllocationType {
  name: string;
  value: number;
}

interface AIRecommendationType {
  key: string;
  code: string;
  action: string;
  confidence: number;
  targetPrice: number;
  stopLoss: number;
  risk: string;
  reason: string;
  timeframe: string;
}

interface MacroIndicatorType {
  key: string;
  indicator: string;
  value: number;
  previousValue: number;
  trend: 'up' | 'down' | 'stable';
  impact: 'positive' | 'negative' | 'neutral';
  description: string;
}

// Mock data with proper types
const marketData: MarketDataType[] = [
  { name: 'Jan', VN_Index: 1000, VN30: 950, Volume: 650000000, RSI: 55, MA20: 980, MA50: 960, BB_Upper: 1020, BB_Lower: 980, MACD: 10, Signal: 8 },
  { name: 'Feb', VN_Index: 1050, VN30: 1000, Volume: 750000000, RSI: 65, MA20: 1020, MA50: 990, BB_Upper: 1070, BB_Lower: 1030, MACD: 12, Signal: 11 },
  { name: 'Mar', VN_Index: 1100, VN30: 1050, Volume: 850000000, RSI: 75, MA20: 1060, MA50: 1020, BB_Upper: 1120, BB_Lower: 1080, MACD: 15, Signal: 13 },
  { name: 'Apr', VN_Index: 1080, VN30: 1030, Volume: 600000000, RSI: 45, MA20: 1070, MA50: 1040, BB_Upper: 1100, BB_Lower: 1060, MACD: 8, Signal: 10 },
  { name: 'May', VN_Index: 1120, VN30: 1070, Volume: 900000000, RSI: 60, MA20: 1090, MA50: 1060, BB_Upper: 1140, BB_Lower: 1100, MACD: 11, Signal: 9 },
];

const sectorAnalysis: SectorAnalysisType[] = [
  {
    key: '1',
    sector: 'Ngân hàng',
    marketCap: 1250000,
    performance: 15.5,
    pe: 12.5,
    outlook: 'Tích cực',
    topPicks: ['VCB', 'TCB', 'MBB'],
    momentum: 85
  },
  {
    key: '2',
    sector: 'Bất động sản',
    marketCap: 850000,
    performance: -5.2,
    pe: 18.2,
    outlook: 'Trung tính',
    topPicks: ['VHM', 'NLG', 'DXG'],
    momentum: 45
  },
  {
    key: '3',
    sector: 'Bán lẻ',
    marketCap: 420000,
    performance: 8.7,
    pe: 15.8,
    outlook: 'Tích cực',
    topPicks: ['MWG', 'PNJ', 'VRE'],
    momentum: 75
  },
  {
    key: '4',
    sector: 'Thép',
    marketCap: 380000,
    performance: -2.8,
    pe: 9.5,
    outlook: 'Tiêu cực',
    topPicks: ['HPG', 'NKG'],
    momentum: 35
  }
];

const sectorAllocation: SectorAllocationType[] = [
  { name: 'Ngân hàng', value: 32 },
  { name: 'Bất động sản', value: 24 },
  { name: 'Bán lẻ', value: 15 },
  { name: 'Thép', value: 8 },
  { name: 'Khác', value: 21 }
];

const aiRecommendations: AIRecommendationType[] = [
  {
    key: '1',
    code: 'VNM',
    action: 'Mua',
    confidence: 85,
    targetPrice: 85000,
    stopLoss: 78000,
    risk: 'Thấp',
    reason: 'Xu hướng tăng mạnh, khối lượng tích cực',
    timeframe: 'Trung hạn'
  },
  {
    key: '2',
    code: 'HPG',
    action: 'Tích lũy',
    confidence: 75,
    targetPrice: 27500,
    stopLoss: 24000,
    risk: 'Trung bình',
    reason: 'Đang trong vùng tích lũy',
    timeframe: 'Dài hạn'
  },
  {
    key: '3',
    code: 'VCB',
    action: 'Chờ',
    confidence: 65,
    targetPrice: 92000,
    stopLoss: 85000,
    risk: 'Cao',
    reason: 'Đang tiếp cận vùng kháng cự mạnh',
    timeframe: 'Ngắn hạn'
  }
];

const macroIndicators: MacroIndicatorType[] = [
  {
    key: '1',
    indicator: 'Lãi suất điều hành',
    value: 4.5,
    previousValue: 5.0,
    trend: 'down',
    impact: 'positive',
    description: 'Giảm 50 điểm cơ bản, hỗ trợ tăng trưởng tín dụng'
  },
  {
    key: '2',
    indicator: 'Lạm phát (CPI)',
    value: 3.8,
    previousValue: 4.2,
    trend: 'down',
    impact: 'positive',
    description: 'Xu hướng giảm, nằm trong mục tiêu kiểm soát'
  },
  {
    key: '3',
    indicator: 'Tăng trưởng GDP',
    value: 5.8,
    previousValue: 5.2,
    trend: 'up',
    impact: 'positive',
    description: 'Phục hồi tích cực, vượt kỳ vọng thị trường'
  },
  {
    key: '4',
    indicator: 'Tỷ giá USD/VND',
    value: 24350,
    previousValue: 24200,
    trend: 'up',
    impact: 'negative',
    description: 'Áp lực tăng nhẹ, cần theo dõi diễn biến'
  },
  {
    key: '5',
    indicator: 'FDI đăng ký',
    value: 8.2,
    previousValue: 7.5,
    trend: 'up',
    impact: 'positive',
    description: 'Tăng 9.3% so với cùng kỳ, triển vọng tích cực'
  }
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const MarketDashboard: React.FC = () => {
  // Mobile detection
  const isMobile = window.innerWidth <= 768;

  // Responsive table scroll configuration
  const scrollConfig = isMobile ? { x: 'max-content' } : undefined;

  // Mobile-optimized columns for sector analysis
  const sectorColumns: ColumnType<SectorAnalysisType>[] = [
    {
      title: 'Ngành',
      dataIndex: 'sector',
      key: 'sector',
      fixed: 'left' as const,
      render: (text: string) => <strong>{text}</strong>,
    },
    {
      title: 'Vốn hóa',
      dataIndex: 'marketCap',
      key: 'marketCap',
      render: (value: number) => new Intl.NumberFormat().format(value),
    },
    {
      title: '%',
      dataIndex: 'performance',
      key: 'performance',
      render: (value: number) => (
        <span style={{ color: value >= 0 ? '#3f8600' : '#cf1322' }}>
          {value >= 0 ? '+' : ''}{value}%
        </span>
      ),
    },
    {
      title: 'P/E',
      dataIndex: 'pe',
      key: 'pe',
    },
    {
      title: 'Triển vọng',
      dataIndex: 'outlook',
      key: 'outlook',
      render: (outlook: string) => {
        const color = outlook === 'Tích cực' ? 'green' : outlook === 'Tiêu cực' ? 'red' : 'blue';
        return <Tag color={color}>{outlook}</Tag>;
      },
    },
    {
      title: 'CP tiêu biểu',
      dataIndex: 'topPicks',
      key: 'topPicks',
      render: (stocks: string[]) => (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
          {stocks.map(stock => (
            <Tag color="blue" key={stock}>
              {stock}
            </Tag>
          ))}
        </div>
      ),
    }
  ];

  // Mobile-optimized columns for AI recommendations
  const aiRecommendationColumns: ColumnType<AIRecommendationType>[] = [
    {
      title: 'Mã CP',
      dataIndex: 'code',
      key: 'code',
      fixed: 'left' as const,
      render: (text: string) => <strong>{text}</strong>,
    },
    {
      title: 'KN',
      dataIndex: 'action',
      key: 'action',
      render: (action: string) => {
        const color = action === 'Mua' ? 'green' : action === 'Bán' ? 'red' : action === 'Tích lũy' ? 'blue' : 'orange';
        return <Tag color={color}>{action}</Tag>;
      },
    },
    {
      title: 'Độ tin cậy',
      dataIndex: 'confidence',
      key: 'confidence',
      render: (value: number) => (
        <Progress percent={value} size="small" steps={5} />
      ),
    },
    {
      title: 'Giá MT',
      dataIndex: 'targetPrice',
      key: 'targetPrice',
      render: (value: number) => (
        <Text strong style={{ color: '#3f8600' }}>
          {(value/1000).toFixed(1)}k
        </Text>
      ),
    },
    {
      title: 'SL',
      dataIndex: 'stopLoss',
      key: 'stopLoss',
      render: (value: number) => (
        <Text type="danger">
          {(value/1000).toFixed(1)}k
        </Text>
      ),
    }
  ];

  // Mobile-optimized columns for macro indicators
  const macroIndicatorColumns: ColumnType<MacroIndicatorType>[] = [
    {
      title: 'Chỉ số',
      dataIndex: 'indicator',
      key: 'indicator',
      fixed: 'left' as const,
      render: (text: string) => <strong>{text}</strong>,
    },
    {
      title: 'Giá trị',
      dataIndex: 'value',
      key: 'value',
      render: (value: number, record: MacroIndicatorType) => (
        <Statistic
          value={value}
          precision={2}
          valueStyle={{ 
            color: record.impact === 'positive' ? '#3f8600' : record.impact === 'negative' ? '#cf1322' : '#1890ff',
            fontSize: isMobile ? '14px' : '16px'
          }}
          prefix={record.trend === 'up' ? <RiseOutlined /> : record.trend === 'down' ? <RiseOutlined rotate={180} /> : null}
          suffix="%"
        />
      ),
    },
    {
      title: 'Thay đổi',
      dataIndex: 'previousValue',
      key: 'change',
      render: (previousValue: number, record: MacroIndicatorType) => {
        const change = record.value - previousValue;
        return (
          <span style={{ color: change >= 0 ? '#3f8600' : '#cf1322' }}>
            {change >= 0 ? '+' : ''}{change.toFixed(2)}
          </span>
        );
      },
    },
    {
      title: 'Tác động',
      dataIndex: 'impact',
      key: 'impact',
      render: (impact: string) => {
        const color = impact === 'positive' ? 'green' : impact === 'negative' ? 'red' : 'blue';
        const text = impact === 'positive' ? 'Tích cực' : impact === 'negative' ? 'Tiêu cực' : 'Trung tính';
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: 'Nhận định',
      dataIndex: 'description',
      key: 'description',
      render: (text: string) => (
        <Tooltip title={text}>
          <Text ellipsis style={{ maxWidth: 200 }}>{text}</Text>
        </Tooltip>
      ),
    }
  ];

  const chartHeight = isMobile ? 200 : 300;

  // Highcharts options for VN-Index chart
  const vnIndexOptions = {
    chart: {
      height: chartHeight
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: marketData.map(data => data.name)
    },
    yAxis: {
      title: {
        text: 'Giá trị'
      }
    },
    tooltip: {
      shared: true
    },
    legend: {
      enabled: true
    },
    series: [
      {
        name: 'VN-Index',
        type: 'line',
        data: marketData.map(data => data.VN_Index),
        color: '#ff7300'
      },
      {
        name: 'Bollinger Bands',
        type: 'arearange',
        data: marketData.map(data => [data.BB_Lower, data.BB_Upper]),
        fillOpacity: 0.1,
        lineWidth: 0,
        color: '#8884d8'
      }
    ]
  };

  // Highcharts options for sector allocation pie chart
  const sectorAllocationOptions = {
    chart: {
      type: 'pie',
      height: chartHeight
    },
    title: {
      text: ''
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.name}: {point.percentage:.1f}%'
        }
      }
    },
    series: [{
      name: 'Phân bổ',
      colorByPoint: true,
      data: sectorAllocation.map((sector, index) => ({
        name: sector.name,
        y: sector.value,
        color: COLORS[index % COLORS.length]
      }))
    }]
  };

  // Highcharts options for sector performance chart
  const sectorPerformanceOptions = {
    chart: {
      height: chartHeight
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: sectorAnalysis.map(sector => sector.sector)
    },
    yAxis: [{
      title: {
        text: 'Hiệu suất (%)'
      }
    }, {
      title: {
        text: 'Động lượng'
      },
      opposite: true
    }],
    series: [{
      name: 'Hiệu suất',
      type: 'column',
      data: sectorAnalysis.map(sector => sector.performance),
      color: '#8884d8'
    }, {
      name: 'Động lượng',
      type: 'line',
      yAxis: 1,
      data: sectorAnalysis.map(sector => sector.momentum),
      color: '#ff7300'
    }]
  };

  return (
    <div style={{ padding: isMobile ? '8px' : '16px' }}>
      {/* Market Overview Section */}
      <section>
        <Title level={4} style={{ fontSize: isMobile ? '18px' : '24px' }}>
          <StockOutlined /> Tổng quan thị trường
        </Title>
        <Row gutter={[8, 8]}>
          <Col xs={12} sm={12} lg={6}>
            <Card size={isMobile ? "small" : "default"}>
              <Statistic
                title={<>VN-Index <Tooltip title="Chỉ số VN-Index hiện tại"><InfoCircleOutlined /></Tooltip></>}
                value={1120}
                precision={2}
                valueStyle={{ color: '#3f8600', fontSize: isMobile ? '16px' : '24px' }}
                prefix={<RiseOutlined />}
                suffix="%"
              />
              <Progress percent={75} size="small" status="active" format={() => 'Xu hướng tăng'} />
            </Card>
          </Col>
          <Col xs={12} sm={12} lg={6}>
            <Card size={isMobile ? "small" : "default"}>
              <Statistic
                title={<>Beta <Tooltip title="Độ biến động so với thị trường"><InfoCircleOutlined /></Tooltip></>}
                value={1.2}
                precision={2}
                valueStyle={{ color: '#1890ff', fontSize: isMobile ? '16px' : '24px' }}
                suffix="β"
              />
              <Text type="secondary" style={{ fontSize: isMobile ? '12px' : '14px' }}>Biến động cao</Text>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card size={isMobile ? "small" : "default"}>
              <HighchartsReact highcharts={Highcharts} options={vnIndexOptions} />
            </Card>
          </Col>
        </Row>
      </section>

      <Divider style={{ margin: isMobile ? '12px 0' : '24px 0' }} />

      {/* AI Recommendations Section */}
      <section>
        <Title level={4} style={{ fontSize: isMobile ? '18px' : '24px' }}>
          <RobotOutlined /> Khuyến nghị AI
        </Title>
        <Card
          size={isMobile ? "small" : "default"}
          extra={
            <Tag icon={<CheckCircleOutlined />} color="success">
              Độ chính xác: 85%
            </Tag>
          }
        >
          <Alert
            message="Phân tích dựa trên mô hình học máy"
            description="Các khuyến nghị được đưa ra dựa trên phân tích kỹ thuật và cơ bản"
            type="info"
            showIcon
            style={{ marginBottom: 16 }}
          />
          <Table
            dataSource={aiRecommendations}
            columns={aiRecommendationColumns}
            pagination={false}
            scroll={scrollConfig}
            size={isMobile ? "small" : "middle"}
          />
        </Card>
      </section>

      <Divider style={{ margin: isMobile ? '12px 0' : '24px 0' }} />

      {/* Macro Analysis Section */}
      <section>
        <Title level={4} style={{ fontSize: isMobile ? '18px' : '24px' }}>
          <GlobalOutlined /> Phân tích vĩ mô
        </Title>
        <Card size={isMobile ? "small" : "default"}>
          <Alert
            message="Cập nhật chỉ số vĩ mô"
            description="Các chỉ số kinh tế vĩ mô quan trọng và tác động đến thị trường"
            type="info"
            showIcon
            style={{ marginBottom: 16 }}
          />
          <Table
            dataSource={macroIndicators}
            columns={macroIndicatorColumns}
            pagination={false}
            scroll={scrollConfig}
            size={isMobile ? "small" : "middle"}
          />
        </Card>
      </section>

      <Divider style={{ margin: isMobile ? '12px 0' : '24px 0' }} />

      {/* Sector Analysis Section */}
      <section>
        <Title level={4} style={{ fontSize: isMobile ? '18px' : '24px' }}>
          <BankOutlined /> Phân tích ngành
        </Title>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Card size={isMobile ? "small" : "default"}>
              <Table
                dataSource={sectorAnalysis}
                columns={sectorColumns}
                pagination={false}
                scroll={scrollConfig}
                size={isMobile ? "small" : "middle"}
              />
            </Card>
          </Col>

          <Col xs={24} lg={12}>
            <Card size={isMobile ? "small" : "default"} title="Phân bổ vốn hóa">
              <HighchartsReact highcharts={Highcharts} options={sectorAllocationOptions} />
            </Card>
          </Col>

          <Col xs={24} lg={12}>
            <Card size={isMobile ? "small" : "default"} title="Hiệu suất ngành">
              <HighchartsReact highcharts={Highcharts} options={sectorPerformanceOptions} />
            </Card>
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default MarketDashboard;
