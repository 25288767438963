import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const SpiderRadarChart: React.FC = () => {
  const options = {
    chart: {
      polar: true,
      type: 'line',
    },
    title: {
      text: 'Spider/Radar Chart',
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
    },
    xAxis: {
      categories: ['Profitability', 'Risk Control', 'University', 'Diversity', 'Reliability', 'Explainability'],
      tickmarkPlacement: 'on',
      lineWidth: 0,
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0,
    },
    series: [{
      name: 'Mock Data',
      data: [65, 59, 90, 81, 56, 55], // Mock values
      pointPlacement: 'on',
    }],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default SpiderRadarChart;
