import React from 'react';
import { Layout, Row, Col } from 'antd';

const { Content } = Layout;

const BotDetail: React.FC = () => {
  return (
    <Layout style={{ 
      height: '100vh',
      background: '#f5f5f5',
      overflow: 'hidden',
    }}>
      <Content style={{ height: '100%', padding: '16px' }}>
        <Row gutter={[16, 16]} style={{ height: '100%', margin: 0 }}>
          {/* Header */}
          <Col xs={24}>
            <div style={{ padding: '16px', background: '#fff', borderBottom: '1px solid #e8e8e8' }}>
              <h1>Bot Detail</h1>
            </div>
          </Col>

          {/* Bot Trading History and Daily Returns */}
          <Col xs={24} style={{ height: '100%', overflowY: 'auto' }}>
            <div style={{ padding: '16px', background: '#fff' }}>
              <h2>Trading History</h2>
              {/* Add logic to display trading history */}
              <h2>Daily Returns</h2>
              {/* Add logic to display daily returns */}
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default BotDetail;
